/* Tester egen state via document event */

import { IProduct } from "../model/product";

let callbackFuncs: Function[] = [];
let localStorage: Storage | null = null;
if (typeof window !== 'undefined') {
	localStorage = window.localStorage;
}
function toggleItem(product: IProduct) {
	if (!localStorage) {
		return;
	}
	let currentCart = localStorage.getItem('cart') || '[]';
	let cart = JSON.parse(currentCart);
	const alreadyAdded = cart.find((item: IProduct) => item._id === product._id);
	if (alreadyAdded) {
		cart = cart.filter((item: IProduct) => item._id !== product._id);
	} else {
		cart.push(product);
	}
	localStorage.setItem('cart', JSON.stringify(cart));
	callbackFuncs.forEach(func => func());
}

function getCart(): IProduct[] {

	if (localStorage) {
		let currentCart = localStorage?.getItem('cart') || '[]';
		return JSON.parse(currentCart);
	}
	return [];

}

function init(document: Document) {
	callbackFuncs = [];
	callbackFuncs.forEach(func => func());
}

export default {
	init,
	cartUpdated: (func: Function) => {
		callbackFuncs.push(func);
	},
	toggleItem,
	getCart
}